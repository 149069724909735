
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function relatedItemsRT () {
    function repeatItems1(items, itemsIndex) {
        return [items(function () {
                function mergeProps(inline, external) {
                    var res = Object.assign({}, inline, external);
                    if (inline.hasOwnProperty('style')) {
                        res.style = _.defaults(res.style, inline.style);
                    }
                    if (inline.hasOwnProperty('className') && external.hasOwnProperty('className')) {
                        res.className = external.className + ' ' + inline.className;
                    }
                    return res;
                }
                function repeatI2(i, iIndex) {
                    return _createElement('span', {
                        'key': i,
                        'className': 'cm_star cm_star__' + (!(this.review_average - i) || this.review_average - i < 0.25 ? 'empty' : this.review_average - i < 0.75 ? 'half' : 'full')
                    });
                }
                return _createElement('div', { 'className': 'cm_product-item grid-item__link' }, _createElement('a', {
                    'href': this.url,
                    'className': 'cm_product-item__image-container'
                }, _createElement('img', {
                    'className': 'cm_product-item__image' + (this.image2 ? ' cm_product-item__image_primary' : ''),
                    'src': this.imageOrDefault(this.resizeImage(this.image)),
                    'alt': this.removeHTML(this.title),
                    'onError': this.onImageError
                }), this.image2 ? _createElement('img', {
                    'className': 'cm_product-item__image cm_product-item__image_secondary',
                    'src': this.resizeImage(this.image2),
                    'alt': this.removeHTML(this.title),
                    'onError': e => this.onImageError(e, 'image2'),
                    'key': '355'
                }) : null), _createElement('div', { 'className': 'cm_product-item__details' }, _createElement('a', mergeProps({
                    'href': this.vendor_url,
                    'className': 'cm_product-item__vendor'
                }, { dangerouslySetInnerHTML: { __html: this.vendor } })), _createElement('a', mergeProps({
                    'href': this.url,
                    'className': 'cm_product-item__title'
                }, { dangerouslySetInnerHTML: { __html: this.title } })), _createElement('div', { 'className': 'cm_product-item__sku-container' }, this.sku ? _createElement('div', {
                    'className': 'cm_product-item__sku',
                    'key': '929'
                }, _createElement('span', { 'className': 'sku-title' }, 'SKU: '), _createElement('span', mergeProps({ 'className': 'sku-value' }, { dangerouslySetInnerHTML: { __html: this.sku } }))) : null), _createElement('div', { 'className': 'cm_product-item__price-container' }, this.on_sale ? _createElement('div', {
                    'className': 'cm_product-item__price cm_product-item__price_compare',
                    'key': '1199'
                }, '\n        ', this.formatPrice(this.compare_at_price), '\n      ') : null, _createElement('div', { 'className': 'cm_product-item__price' }, this.price_varies ? _createElement('span', { 'key': '1402' }, 'From: ') : null, '\n        ', this.formatPrice(this.price), '\n      '))), _createElement('div', { 'className': 'cm_product-item__buttons' }, _createElement('form', {
                    'action': '/cart/add',
                    'method': 'post',
                    'className': 'cm_product-item__buttons-form',
                    'id': this.id,
                    'encType': 'multipart/form-data'
                }, this.variant_ids.length > 1 ? [_createElement('a', {
                        'className': 'cm_button cm_button__secondary',
                        'href': this.url,
                        'aria-label': 'button',
                        'key': '16941'
                    }, '\n          ', this.out_of_stock ? 'Sold out' : 'Select options', '\n        ')] : null, this.variant_ids.length === 1 ? [
                    _createElement('input', {
                        'type': 'hidden',
                        'name': 'id',
                        'value': this.variant_ids,
                        'key': '19561'
                    }),
                    _createElement('input', {
                        'type': 'hidden',
                        'name': 'quantity',
                        'value': '1',
                        'key': '19563'
                    }),
                    _createElement('button', {
                        'className': 'cm_button cm_button__primary',
                        'type': 'submit',
                        'data-form-id': this.id,
                        'disabled': this.out_of_stock,
                        'key': '19565'
                    }, '\n          ', this.out_of_stock ? 'Sold out' : 'Add to cart', '\n        ')
                ] : null)), [this.review_count ? _createElement.apply(this, [
                        'div',
                        {
                            'className': 'cm_review-stars',
                            'key': '23970'
                        },
                        _map([
                            0,
                            1,
                            2,
                            3,
                            4
                        ], repeatI2.bind(this)),
                        _createElement('span', { 'className': 'cm_review-count' }, '(', this.review_count, ')')
                    ]) : null], this.price ? _createElement('p', {
                    'className': 'affirm-as-low-as',
                    'data-amount': this.price * 100,
                    'data-affirm-type': 'logo',
                    'data-affirm-color': 'blue',
                    'key': '2796'
                }) : null);
            }, { count: undefined })];
    }
    return _createElement('div', { 'className': 'cm_related-products' }, _createElement('div', { 'className': 'cm_related-products-title-container' }, _createElement('h2', { 'className': 'cm_related-products-title' }, 'You might also like')), _createElement.apply(this, [
        'div',
        { 'className': 'cm_related-products-list cmRepeater_items' },
        _map(this.items, repeatItems1.bind(this))
    ]));
}
        export const componentNames = []